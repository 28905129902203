import { ReactElement, ReactNode, useState } from "react";

import SignInModal from "./SignInModal";

type SignInButtonProps = {
  children?: ReactNode | ((openModal?: () => void) => ReactElement);
};

export default function SignInButton(props: SignInButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  return (
    <>
      {typeof props.children === "function"
        ? props.children(openModal)
        : props.children}
      <SignInModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
