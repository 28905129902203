import { signIn } from "next-auth/react";
import { FaGoogle, FaFacebook, FaApple } from "react-icons/fa";

import Button from "../Button";
import Modal from "../modal/Modal";

type LoginModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function SignInModal(props: LoginModalProps) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <p className="mb-4 text-lg font-medium text-gray-700">Sign in with</p>
      <Button
        size="lg"
        appearance="white"
        className="w-full justify-center"
        onClick={() => signIn("google")}
      >
        <FaGoogle className="mr-1" /> Google
      </Button>
      <Button
        size="lg"
        appearance="white"
        className="mt-3 w-full justify-center"
        onClick={() => signIn("apple")}
      >
        <FaApple className="mr-1" /> Apple
      </Button>
      <Button
        size="lg"
        appearance="white"
        className="mt-3 w-full justify-center"
        onClick={() => signIn("facebook")}
      >
        <FaFacebook className="mr-1" /> Facebook
      </Button>
    </Modal>
  );
}
