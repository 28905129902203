import { Disclosure } from "@headlessui/react";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";

import routes from "../../../lib/routes";
import SignInButton from "../../auth/SignInButton";

export function ProfileMobileMenu() {
  const session = useSession();

  if (session.status !== "authenticated") {
    return (
      <div className="pt-4 pb-3">
        <div className="mt-3 px-2 space-y-1">
          <SignInButton>
            {(openModal) => (
              <button
                // as="button"
                // href="#"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                onClick={openModal}
              >
                Sign In
              </button>
            )}
          </SignInButton>
        </div>
      </div>
    );
  }

  const profileImage = session.data.user?.image;

  return (
    <div className="pt-4 pb-3">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          {profileImage && (
            <img className="h-10 w-10 rounded-full" src={profileImage} alt="" />
          )}
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-white">
            {session.data.user?.name}
          </div>
          <div className="text-sm font-medium text-gray-400">
            {session.data.user?.email}
          </div>
        </div>
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"*/}
        {/*>*/}
        {/*  <span className="sr-only">View notifications</span>*/}
        {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
        {/*</button>*/}
      </div>

      <div className="mt-3 px-2 space-y-1">
        {/*<Disclosure.Button*/}
        {/*  as="a"*/}
        {/*  href="#"*/}
        {/*  className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"*/}
        {/*>*/}
        {/*  Your Profile*/}
        {/*</Disclosure.Button>*/}
        {/*<Link href={routes.settings()}>*/}
        {/*  <Disclosure.Button*/}
        {/*    as="a"*/}
        {/*    href={routes.settings()}*/}
        {/*    className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"*/}
        {/*  >*/}
        {/*    Settings*/}
        {/*  </Disclosure.Button>*/}
        {/*</Link>*/}
        <Disclosure.Button
          as="button"
          className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
          onClick={() => signOut()}
        >
          Sign out
        </Disclosure.Button>
      </div>
    </div>
  );
}
