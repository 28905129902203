import SignInButton from "../../auth/SignInButton";

export default function NavSignInButton() {
  return (
    <SignInButton>
      {(openModal) => (
        <button
          type="button"
          onClick={openModal}
          className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
        >
          Sign In
        </button>
      )}
    </SignInButton>
  );
}
