import { Menu, Transition } from "@headlessui/react";
import { url } from "gravatar";
import { signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { Fragment } from "react";

import routes from "../../../lib/routes";
import { classNames } from "../../../lib/utils/css";

export default function ProfileDropdown() {
  const session = useSession();

  if (session.status !== "authenticated") {
    return null;
  }

  const gravatar = session.data.user?.email
    ? url(session.data.user?.email, { s: "64px", default: "mp" })
    : undefined;

  const profileImage = session.data.user?.image ?? gravatar;
  return (
    <Menu as="div" className="ml-4 relative flex-shrink-0 z-50">
      <div>
        <Menu.Button className="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
          <span className="sr-only">Open user menu</span>
          {profileImage && (
            <img
              className="h-8 w-8 rounded-full"
              src={profileImage}
              alt=""
              referrerPolicy="no-referrer"
            />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {/*<Menu.Item>*/}
          {/*  {({ active }) => (*/}
          {/*    <a*/}
          {/*      href="#"*/}
          {/*      className={classNames(*/}
          {/*        active ? "bg-gray-100" : "",*/}
          {/*        "block px-4 py-2 text-sm text-gray-700"*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      Your Profile*/}
          {/*    </a>*/}
          {/*  )}*/}
          {/*</Menu.Item>*/}
          {/*<Menu.Item>*/}
          {/*  {({ active }) => (*/}
          {/*    <Link href={routes.settings()}>*/}
          {/*      <a*/}
          {/*        className={classNames(*/}
          {/*          active ? "bg-gray-100" : "",*/}
          {/*          "block px-4 py-2 text-sm text-gray-700"*/}
          {/*        )}*/}
          {/*      >*/}
          {/*        Settings*/}
          {/*      </a>*/}
          {/*    </Link>*/}
          {/*  )}*/}
          {/*</Menu.Item>*/}
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => signOut()}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block w-full text-left px-4 py-2 text-sm text-gray-700"
                )}
              >
                Sign out
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
