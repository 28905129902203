import { ReactNode } from "react";

import { classNames } from "../../lib/utils/css";

type ContainerProps = {
  children?: ReactNode;
  className?: string;
};

export default function Container(props: ContainerProps) {
  return (
    <div
      className={classNames(
        "max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
