import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useSession } from "next-auth/react";

import NavSignInButton from "./navbar/NavSignInButton";
import ProfileDropdown from "./navbar/ProfileDropdown";
import { ProfileMobileMenu } from "./navbar/ProfileMobileMenu";

function Navbar() {
  const session = useSession();
  const isAuthenticated = session.status === "authenticated";

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <span className="text-white font-black text-2xl">Thickt</span>
                  {/*<img*/}
                  {/*  className="block lg:hidden h-8 w-auto"*/}
                  {/*  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"*/}
                  {/*  alt="Thickt"*/}
                  {/*/>*/}
                  {/*<img*/}
                  {/*  className="hidden lg:block h-8 w-auto"*/}
                  {/*  src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"*/}
                  {/*  alt="Thickt"*/}
                  {/*/>*/}
                </div>
                {/*<div className="hidden lg:block lg:ml-6">*/}
                {/*  <div className="flex space-x-4">*/}
                {/*    /!* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" *!/*/}
                {/*    <a*/}
                {/*      href="#"*/}
                {/*      className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                {/*    >*/}
                {/*      Dashboard*/}
                {/*    </a>*/}
                {/*    <a*/}
                {/*      href="#"*/}
                {/*      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                {/*    >*/}
                {/*      Team*/}
                {/*    </a>*/}
                {/*    <a*/}
                {/*      href="#"*/}
                {/*      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                {/*    >*/}
                {/*      Projects*/}
                {/*    </a>*/}
                {/*    <a*/}
                {/*      href="#"*/}
                {/*      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"*/}
                {/*    >*/}
                {/*      Calendar*/}
                {/*    </a>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>

              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:block lg:ml-4">
                {isAuthenticated && (
                  <div className="flex items-center">
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"*/}
                    {/*>*/}
                    {/*  <span className="sr-only">View notifications</span>*/}
                    {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                    {/*</button>*/}

                    {/* Profile dropdown */}
                    <ProfileDropdown />
                  </div>
                )}

                {!isAuthenticated && <NavSignInButton />}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            {/*<div className="px-2 pt-2 pb-3 space-y-1">*/}
            {/*  /!* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" *!/*/}
            {/*  <Disclosure.Button*/}
            {/*    as="a"*/}
            {/*    href="#"*/}
            {/*    className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"*/}
            {/*  >*/}
            {/*    Dashboard*/}
            {/*  </Disclosure.Button>*/}
            {/*  <Disclosure.Button*/}
            {/*    as="a"*/}
            {/*    href="#"*/}
            {/*    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
            {/*  >*/}
            {/*    Team*/}
            {/*  </Disclosure.Button>*/}
            {/*  <Disclosure.Button*/}
            {/*    as="a"*/}
            {/*    href="#"*/}
            {/*    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
            {/*  >*/}
            {/*    Projects*/}
            {/*  </Disclosure.Button>*/}
            {/*  <Disclosure.Button*/}
            {/*    as="a"*/}
            {/*    href="#"*/}
            {/*    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"*/}
            {/*  >*/}
            {/*    Calendar*/}
            {/*  </Disclosure.Button>*/}
            {/*</div>*/}
            {/*border-t border-gray-700*/}
            <ProfileMobileMenu />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
