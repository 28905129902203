import React, { Suspense } from "react";

import ErrorBoundary from "../ErrorBoundary";

import Navbar from "./Navbar";

type MainProps = {
  children?: React.ReactNode;
};

function Main(props: MainProps) {
  return (
    <main className="bg-white">
      <Navbar />

      <ErrorBoundary fallback={<div />}>
        <Suspense fallback={<div />}>{props.children}</Suspense>
      </ErrorBoundary>
    </main>
  );
}

export default Main;
