import { MouseEventHandler, ReactNode } from "react";

import { classNames } from "../lib/utils/css";

type Size = "xs" | "sm" | "md" | "lg" | "xl";
type Appearance = "primary" | "secondary" | "white";

type ButtonProps = {
  className?: string;
  size?: Size;
  appearance?: Appearance;
  type?: "button" | "submit" | "reset";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: ReactNode;
};

export default function Button(props: ButtonProps) {
  const {
    className = "",
    size = "md",
    appearance = "primary",
    type = "button",
    onClick,
    disabled,
    children,
  } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "inline-flex items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2",
        getClassesForSize(size),
        getClassesForAppearance(appearance),
        className
      )}
    >
      {children}
    </button>
  );
}

function getClassesForSize(size: Size) {
  switch (size) {
    case "xs":
      return "px-2.5 py-1.5 text-xs rounded";
    case "sm":
      return "px-3 py-2 text-sm leading-4 rounded-md";
    case "md":
      return "px-4 py-2 text-sm rounded-md";
    case "lg":
      return "px-4 py-2 text-base rounded-md";
    case "xl":
      return "px-6 py-3 text-base rounded-md";
    default:
      return "";
  }
}

function getClassesForAppearance(type: Appearance) {
  switch (type) {
    case "primary":
      return "border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500";
    case "secondary":
      return "border-gray-300 text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500";
    case "white":
      return "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500";
    default:
      return "";
  }
}
